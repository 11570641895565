import React from 'react';
import Router from 'routes';
import './App.css';

function App() {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
