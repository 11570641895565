const australiaCities: Array<string> = [
  'Sydney',
  'Melbourne',
  'Brisbane',
  'Perth',
  'Adelaide',
  'Gold Coast',
  'Hobart',
];

export default australiaCities;
