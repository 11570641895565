const PROFILE_SETTINGS_MENU: Array<string> = [
  'My Requests',
  'Received Request',
  'Account Settings',
];

const PROFILE_SETTINGS_SUB_MENU: Array<string> = [
  'All Requests',
  'Awaiting Response',
  'Not Completed',
  'Not Reviewed',
];
export { PROFILE_SETTINGS_MENU, PROFILE_SETTINGS_SUB_MENU };
